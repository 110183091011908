<template>
  <CDataTable
    hover
    :fields="tableFields"
    :items="tableItems"
    class="downloads"
    :noItemsView="{
      noItems: messages.tables.noDownloads,
    }"
  >
    <template #status="{ item }">
      <td>
        <CIcon
          v-if="item.status === 'RELEASED'"
          name="cil-check-circle"
          customClasses="icon success"
        />
        <CIcon
          v-if="item.status === 'LOADING'"
          name="cil-arrow-bottom"
          customClasses="icon pending moveDown anim-speed--fast anim-iteration--infinite"
        />
        <CIcon
          v-if="item.status === 'FAILED'"
          name="cil-x-circle"
          customClasses="icon failed"
        />
      </td>
    </template>
    <template #data="{ item }">
      <td class="central-cell">
        <span class="filter-data">{{ item.filterData }}</span
        ><br />
        <span class="date">{{ item.date }}</span>
      </td>
    </template>
    <template #button="{ item }">
      <td class="">
        <span v-if="item.status === 'LOADING'" class="text pending"
          >{{ messages.common.pending }}...</span
        >
        <span v-if="item.status === 'FAILED'" class="text failed">{{
          messages.common.thereWasAnError
        }}</span>
        <CButton
          :class="item.btnEnabled ? 'enabled' : 'disabled'"
          :disabled="!item.btnEnabled"
          v-if="item.status === 'RELEASED'"
          color="success"
          style="white-space: nowrap"
          @click="downloadFile($event, item)"
        >
          {{
            item.btnEnabled
              ? `${messages.common.donwload} CSV`
              : `${messages.common.donwloading} CSV`
          }}
        </CButton>
      </td>
    </template>
  </CDataTable>
</template>
<script>
import filterHelpers from "@/helpers/filterHelpers";
import Api from "@/services/Api";
import { saveAs } from "file-saver";
export default {
  name: "TableAccessoriesDownloads",
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableFields: ["status", "data", "button"],
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    tableItems() {
      const items = [];
      this.tableData.map((tableItem) => {
        const item = {
          status: tableItem.status,
          filterData: this.getItemFilterData(tableItem.searchFilter),
          date: tableItem.lockedAt.split("T")[0],
          reportCode: tableItem.reportCode,
          btnEnabled: true,
        };
        items.push(item);
      });
      return items;
    },
  },
  methods: {
    getConcessionName(code) {
      const concessionName = this.$store.getters[
        "concessions/concessionList"
      ].find((c) => c.concessionCode === code).businessName;
      return concessionName;
    },
    getCategoryValue(key, value) {
      if (key === "countryCode" || key === "regionalCode") {
        const currentLang = this.$store.getters.getCurrentLang;
        return filterHelpers.optionsAndValues([value], currentLang)[0].label;
      } else if (key === "concessionCode") {
        return this.getConcessionName(value);
      } else {
        return value.toUpperCase();
      }
    },
    getItemFilterData(filterData) {
      let categoryStr;
      let categoryValue;
      const categoryKeys = [
        "concessionCode",
        "countryCode",
        "regionalCode",
        "aspm",
      ];
      const since = this.messages.filters.months[filterData.sinceMonth];
      const upTo = this.messages.filters.months[filterData.upToMonth];

      Object.entries(filterData).forEach(([key, value]) => {
        if (categoryKeys.includes(key) && value !== "") {
          categoryStr = this.messages.common.areaCategories[key];
          categoryValue = this.getCategoryValue(key, value);
        }
      });

      return `${categoryStr} ${categoryValue} - De ${since} a ${upTo} | ${filterData.fiscalYear} ${filterData.isCumulative ? "" : "- Mensual"} ${filterData.byDc ? "- DC" : ""}`;
    },
    async downloadFile(event, item) {
      event.preventDefault();
      const reportCode = item.reportCode;
      item.btnEnabled = false;
      const fileName = /[^/]*$/.exec(reportCode)[0];
      const { data } = await Api.downloadFileRequest(
        `/marketing/accTrackingDetail/download?reportCode=${reportCode}`
      );
      if (data && data.type === "text/xml") {
        item.btnEnabled = true;
        saveAs(data, `${fileName}.csv.zip`);
      }
    },
  },
};
</script>

<style lang="scss">
.downloads {
  .table {
    thead {
      // display: none;
    }

    td {
      border-bottom: 1px solid #d8dbe0;
      &.central-cell {
        width: 100%;
        white-space: break-spaces;
      }
      .filter-data {
        font-size: 1rem;
        color: #000000;
      }
      .date {
        font-size: 1em;
      }
    }
  }
  .icon {
    &.pending {
      height: 1.5rem;
    }
    &.success {
      width: 2rem;
      height: 2rem;
      color: #6cb31d;
    }
    &.failed {
      width: 2rem;
      height: 2rem;
    }
  }

  .pending {
    color: #888888;
  }
  .failed {
    color: #c3002f;
  }

  .text {
    &.pending {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 0.875rem;
    }
    &.failed {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 0.875rem;
    }
  }
}
</style>
