<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="12" xxl="10">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
              <CCol sm="4" xxl="2">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol sm="8" xxl="6">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonthsRange />
              </CCol>
              <CCol sm="6" xxl="3">
                <label class="filter-label">{{
                  messages.filters.cumulativeOrNot
                }}</label>
                <FilterMonthlyDetail />
              </CCol>
              <CCol sm="6" xxl="3">
                <label class="filter-label">{{
                  messages.filters.modelOrDc
                }}</label>
                <FilterModelOrDc />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow alignHorizontal="between">
              <CCol>
                <CButton type="submit" color="primary">Mostrar</CButton>
              </CCol>
              <CCol class="text-right">
                <CButton
                  color="success"
                  style="white-space: nowrap"
                  @click="requestDownload()"
                  >Solicitar descarga
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <table-accessories
          :table-data="tableData"
          :request-params="requestParams"
        >
          <span slot="area"> {{ selectedArea }} {{ selectedArea && ' - ' }} </span>
          <span slot="period">{{ selectedPeriod }} / </span>
          <span slot="fiscalYear">{{ selectedFY }}</span>
      </table-accessories>
      </CCol>
    </CRow>
    <CRow v-if="showDownloadsArea" id="downloads">
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol
                sm="12"
                class="d-flex align-items-center justify-content-between"
              >
                {{ "Área de Descargas" }}
                <CButton
                  class="btn-reload"
                  @click="
                    loadingData = true;
                    getDownloadsList(selectedStatus);
                  "
                >
                  <CIcon
                    name="cil-reload"
                    :customClasses="`icon reload ${loadingData ? 'spin' : ''}`"
                  />
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12" class="d-flex align-items-center pt-2">
                Mostrar
                <CSelect
                  class="mb-0 ml-2"
                  :options="[
                    { value: '', label: 'Todas' },
                    { value: 'RELEASED', label: messages.common.released },
                    { value: 'LOADING', label: messages.common.pending2 },
                    { value: 'FAILED', label: messages.common.failed },
                  ]"
                  :value.sync="selectedStatus"
                  @change="getDownloadsList(selectedStatus)"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <table-accessories-downloads
              :tableData="downloadsTableData"
            ></table-accessories-downloads>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { CIcon } from "@coreui/vue";
import FiltersAreaCombo from "@/components/organisms/FiltersAreaCombo";
import FilterFY from "@/components/molecules/FilterFY";
import FilterMonthsRange from "@/components/molecules/FilterMonthsRange";
import FilterMonthlyDetail from "@/components/molecules/FilterMonthlyDetail";
import FilterModelOrDc from "@/components/molecules/FilterModelOrDc";
import Loader from "@/components/atoms/Loader";
import TableAccessories from "@/components/organisms/TableAccessories";
import TableAccessoriesDownloads from "@/components/organisms/TableAccessoriesDownloads";
import downloadsJsonData from "@/helpers/downloadData.json";
import tableDataModel from "@/helpers/accessoriesTableDataModel.json";
import tableDataDc from "@/helpers/accessoriesTableDataDc.json";
import Api from "@/services/Api";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

export default {
  name: "Accessories",
  components: {
    FilterFY,
    FilterMonthsRange,
    FilterMonthlyDetail,
    FilterModelOrDc,
    CIcon,
    FiltersAreaCombo,
    Loader,
    TableAccessories,
    TableAccessoriesDownloads,
    loadingData: false,
    selectedStatus: "",
  },
  data() {
    return {
      cardCollapse: true,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      areaError: false,
      showLoader: false,
      showTable: false,
      loadingData: false,
      selectedStatus: "",
      downloadsTableData: [],
      showDownloadsArea: downloadsJsonData.length > 0,
      tableData: [],
      requestParams: {},
      selectedArea: null,
      selectedFY: null,
      selectedPeriod: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
  },
  methods: {
    submitFilterValues(event) {
      event.preventDefault();
      if (!this.$store.getters.getFiltersAreaComboParams) {
        this.areaError = true;
      } else {
        this.areaError = false;
        const params = this.getParams();
        this.requestParams = params;
        params.modelOrDc === "model"
          ? this.makeTrackingByModelQuery(params)
          : this.makeTrackingByDcQuery(params);
        // this.showTable = true;
      }
    },
    getParams() {
      const currentLang = this.$store.getters.getCurrentLang == "es" ? 1 : 2;
      return {
        ...this.$store.getters.getFiltersAreaComboParams,
        fiscalYear: this.$store.getters.getFilterFYValue,
        sinceMonth: this.$store.getters.getFilterMonthSinceValue,
        upToMonth: this.$store.getters.getFilterMonthUpToValue,
        isCumulative: this.$store.getters.getFilterIsCumulativeValue,
        modelOrDc: this.$store.getters.getFilterModelOrDcValue,
        languageId: currentLang,
      };
    },
    makeTrackingByModelQuery(params) {
      this.showLoader = true;
      this.showTable = false;
      Api.sendPost("/marketing/accTracking/model", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.setTableTitle(params);
            // Hide the loader and sow the table
            this.showLoader = false;
            this.showTable = true;
            // Set the processed data variable to send to the DataTable component
            this.tableData = response.data.data;
          }
        }
      });
    },
    makeTrackingByDcQuery(params) {
      this.showLoader = true;
      this.showTable = false;
      Api.sendPost("/marketing/accTracking/dc", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.setTableTitle(params);
            // Hide the loader and sow the table
            this.showLoader = false;
            this.showTable = true;
            // Set the processed data variable to send to the DataTable component
            this.tableData = response.data.data;
          }
        }
      });
    },
    getDownloadsList(downloadsStatus) {
      Api.sendGet(
        `sys/report/request/list?reportName=acc_tracking_detail&status=${downloadsStatus}`
      ).then((response) => {
        if (response.status === 200 && response.data.code === 200) {
          if (response.data.data) {
            this.downloadsTableData = response.data.data;
          }
        } else {
          console.error(
            `List query ERROR -> ${response.status} - ${response.data.code} ${response.data.message}`
          );
        }
        setTimeout(() => (this.loadingData = false), 300);
      });
    },
    requestDownload() {
      if (!this.$store.getters.getFiltersAreaComboParams) {
        this.areaError = true;
      } else {
        this.areaError = false;
        const params = this.getParams();
        this.requestParams = params;
        // params.modelOrDc === "model"
        //     ? this.makeTrackingByModelQuery(params)
        //     : this.makeTrackingByDcQuery(params);
        Api.sendPost("marketing/accTrackingDetail/request", params).then(
          (response) => {
            if (response.status === 200 && response.data.code === 200) {
              this.getDownloadsList(this.selectedStatus);
              document.getElementById("downloads").scrollIntoView();
            }
          }
        );
      }
    },
    setTableTitle(params) {
      this.selectedArea = getAreaName(params, this.messages);
      this.selectedFY = formatFiscalYear(params.fiscalYear);
      const sinceMonth = this.messages.filters.months[params.sinceMonth];
      const upToMonth = this.messages.filters.months[params.upToMonth]
      this.selectedPeriod = sinceMonth === upToMonth ? sinceMonth : `de ${sinceMonth} a ${upToMonth}`;
    },
  },
  created() {
    this.getDownloadsList(this.selectedStatus);
  },
};
</script>

<style lang="scss">
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #7dc74e;
  border-color: #64ac36;
}
.icon {
  &.reload {
    width: 1.3rem;
    height: 1.3rem;
  }
}
.btn-reload {
  &:hover {
    background-color: #ebedef;
  }
  &:focus {
    box-shadow: none;
  }
}
</style>
