<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-baseline">
      <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
        <strong>{{ messages.tables.results }} </strong>
        <slot name="area"></slot>
        <slot name="period"></slot>
        <slot name="fiscalYear"></slot>
      </span>
      <CButton color="light" class="gtm-tag-export" @click="exportHTMLToExcel">
        {{ messages.tables.export.toUpperCase() }}
        <LogoExcel />
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        outlined
        border
        :table-filter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
        clickableRows
        id="excelArea"
        :fields="
          this.requestParams.modelOrDc === 'model'
            ? tableFieldsModel
            : tableFieldsDC
        "
        :items="
          this.requestParams.modelOrDc === 'model'
            ? tableItemsModel
            : tableItemsDC
        "
      >
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import LogoExcel from "@/components/atoms/LogoExcel";
import formatNum from "@/utils/formatNum";
import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";

export default {
  name: "TableAccessories",
  components: {
    LogoExcel,
  },
  props: {
    tableData: {
      type: [Array, Object],
      required: true,
    },
    requestParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // tableFieldsModel: ["Mes", "Código Modelo", "NCS", "€", "€/NCS", "Total"],
      // tableFieldsDC: ["Mes", "Familia DC", "DC", "€", "Unidades"],
      // tableItems: [],
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    tableFieldsModel() {
      const cells = [
        { key: "modelCode", label: "Código Modelo" },
        { key: "ncs", label: "NCS" },
        { key: "invoicing", label: "€" },
        { key: "euroNcs", label: "€/NCS " },
      ];
      if (!this.requestParams.isCumulative) {
        cells.unshift({ key: "month", label: "Mes" });
      }
      return cells;
    },
    tableFieldsDC() {
      const cells = [
        { key: "family", label: "Familia DC" },
        { key: "dc", label: "DC" },
        { key: "descriptionDc", label: "Descripción DC" },
        { key: "invoicing", label: "€" },
        { key: "quantity", label: "Unidades " },
      ];
      if (!this.requestParams.isCumulative) {
        cells.unshift({ key: "month", label: "Mes" });
      }
      return cells;
    },
    tableItemsModel() {
      const computedItems = this.tableData.modelContents;
      computedItems.map((row) => {
        if (!isNaN(row.month)) {
          row.month = this.messages.filters.months[row.month];
        }
        row.cumulative = "";
      });

      let totalAmount = this.tableData.totalAmount;
      totalAmount.month = "Total";
      totalAmount.modelCode = this.requestParams.isCumulative ? "Total" : "-";
      totalAmount._classes = "dark-gray";
      if (!computedItems.includes(totalAmount)) {
        computedItems.push(totalAmount);
      }
      computedItems.map((item) => {
        for (let key in item) {
          if (item[key] === null) {
            item[key] = "-";
          }
        }
        let cellClasses = item._cellClasses;
        cellClasses = {
          ...cellClasses,
          invoicing: "eur",
          euroNcs: "eur",
        };
        item._cellClasses = cellClasses;
        for (const cell in cellClasses) {
          if (
            cellClasses[cell].includes("eur") &&
            !isNaN(item[cell]) &&
            isFinite(item[cell])
          ) {
            item[`${cell}`] = `${formatNum.get(item[cell], 2)}€`;
          } else if (
            item[cell] === null ||
            (!isFinite(item[cell]) && !item[cell].includes("€"))
          ) {
            item[`${cell}`] = "-";
          }
        }
      });
      return computedItems;
    },
    tableItemsDC() {
      const computedItems = this.tableData;
      computedItems.map((item) => {
        if (!isNaN(item.month)) {
          item.month = this.messages.filters.months[item.month];
        }
        let cellClasses = item._cellClasses;
        cellClasses = {
          ...cellClasses,
          invoicing: "eur",
        };
        item._cellClasses = cellClasses;
        for (const cell in cellClasses) {
          if (
            cellClasses[cell].includes("eur") &&
            !isNaN(item[cell]) &&
            isFinite(item[cell])
          ) {
            item[`${cell}`] = `${formatNum.get(item[cell], 2)}€`;
          } else if (
            item[cell] === null ||
            (isFinite(item[cell]) && !item[cell].includes("€"))
          ) {
            item[`${cell}`] = "-";
          }
        }
      });
      return computedItems;
    },
  },
  methods: {
    getMonth(month) {
      return this.requestParams.isCumulative
        ? ""
        : this.messages.filters.months[month];
    },
    exportHTMLToExcel() {
      ExportHTML2Excel(
        document.querySelector("#excelArea table.table"),
        this.messages.accessories.title
      );
    },
  },
};
</script>

<style scoped></style>
