import { render, staticRenderFns } from "./TableAccessories.vue?vue&type=template&id=79392f09&scoped=true&"
import script from "./TableAccessories.vue?vue&type=script&lang=js&"
export * from "./TableAccessories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79392f09",
  null
  
)

export default component.exports