<template>
  <CInputRadioGroup
    :options="radioOptions"
    :custom="true"
    :checked.sync="radioValue"
    color="success"
    :inline="true"
  />
</template>

<script>
export default {
  name: "FilterModelOrDc",
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    radioOptions() {
      return [
        { value: "model", label: this.messages.filters.model },
        { value: "DC", label: "DC" },
      ];
    },
    radioValue: {
      get() {
        if (!this.selectedValue) {
          this.$store.dispatch("saveFilterModelOrDcValue", "model");
          return "model";
        }
        this.$store.dispatch("saveFilterModelOrDcValue", this.selectedValue);
        return this.selectedValue;
      },
      set(value) {
        this.$store.dispatch("saveFilterModelOrDcValue", value);
        this.selectedValue = value;
      },
    },
  },
};
</script>

<style scoped>

</style>